<template>
  <div class="table">
    <button
      v-if="!dropdownDisabled"
      class="table-title"
      :class="{ open: isOpen }"
      @click="toggleTable"
    >
      {{ title || $t('tournaments.scheduleTable_default') }}
      <Icon inline :name="isOpen ? 'arrow-down' : 'arrow-up'" />
    </button>

    <template v-if="isOpen">
      <tabs-menu
        v-if="tabsList.length > 1"
        v-model="activeTab"
        :list="tabsList"
      >
        <template v-if="$scopedSlots.round" #tab="{ tab }">
          <slot name="round" :round="{ ...tab }" />
        </template>
      </tabs-menu>

      <!-- в швецарской системе бек присылвает [[]] вместо [] если матчей нет -->
      <div
        v-if="matches.flat().length"
        class="pvp-table adaptive stripped"
      >
        <div class="table-header">
          <div class="table-row">
            <cell-header t="date.single" />
            <cell-header t="matches.single" class="centered" />
            <cell-header />
          </div>
        </div>
        <div class="table-body">
          <div
            v-for="(match, index) in matches"
            :key="index"
            class="table-row"
          >
            <cell-match :id="match.id" />
            <cell-date
              :ts="match.tsStartTime"
              format="ddd_dd_MMMM_HH_mm"
            />
            <slot name="match" :match="match">
              <cell-match-link :id="match.id" />
            </slot>
          </div>
        </div>
      </div>
      <div v-else class="error">
        <div class="error__img">
          <Icon name="pvp-swords" :size="92" />
        </div>
        {{ $t('change.tn_rent_match_map_start') }}
        {{ getStartTimeString(table[activeTab].tsStartTime) }}.
        {{ $t('global.matchesWillBeFormedLater') }}
      </div>
    </template>
  </div>
</template>

<script>
import { getDateString } from '@utils/time.js';
import CellMatch from '@components/TableCells/CellMatch.vue';
import CellHeader from '@components/TableCells/CellHeader.vue';
import CellDate from '@components/TableCells/CellDate.vue';
import CellMatchLink from '@components/TableCells/CellMatchLink.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'TournamentScheduleTable',
  components: {
    Icon,
    CellMatchLink,
    CellDate,
    CellHeader,
    CellMatch,
  },
  props: {
    table: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      required: true,
    },
    dropdownDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    activeTab: 0,
    isOpen: true,
  }),
  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    tournament() {
      return this.getTournament(this.id);
    },
    tabsList() {
      return this.table.map((round) => ({
        title: round.name,
        ...round,
      }));
    },
    round() {
      const startIndex =
        (this.table.length && this.table[0].indexNumber) || 1;
      return this.table.find(
        (round) => round.indexNumber === this.activeTab + startIndex,
      );
    },
    matches() {
      return this.round?.matches || null;
    },
  },
  methods: {
    toggleTable() {
      this.isOpen = !this.isOpen;
    },

    getStartTimeString(timestamp) {
      return getDateString(timestamp, 'ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-menu {
  margin-top: 18px;
}

.pvp-table.stripped {
  margin-top: 20px;

  .table-body {
    .table-column:nth-child(1) {
      @include min-laptop() {
        padding-left: 0;
      }
    }
  }
}

.table {
  margin-top: 20px;

  &-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.22;
    color: white;
    margin: 0;
    background: none;
    padding: 0;
    border: none;

    .icon {
      stroke: white;
      font-size: 14px;
    }

    @include min-tablet() {
      flex-shrink: 0;
    }
    @include max-tablet() {
      padding: 0 12px;
    }
  }

  &-header {
    @include max-laptop() {
      display: none;
    }
  }

  &-row {
    @include min-laptop() {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
    }
  }

  &-body &-column {
    @include min-laptop() {
      &:nth-child(1) {
        order: 1;
      }

      &:nth-child(2) {
        order: 0;
        padding-left: 30px;
      }

      &:nth-child(3) {
        order: 2;
      }
    }

    @include max-laptop() {
      align-items: flex-start;
      ::v-deep .column-title {
        flex: 0 0 55px;
      }
    }
  }
}

.error {
  margin-top: 20px;
  text-align: center;
  background: #2d2d30;
  padding: 32px 20px 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22.5px;
  letter-spacing: 0.3px;
  border-radius: 4px;
}

.error__img {
  color: #000000;
  margin: 0 auto 32px;
}
</style>
