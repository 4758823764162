<template>
  <div class="table-column">
    <BaseLink
      v-if="isVisible"
      icon-right="angle-right"
      :to="{ name: 'match', params: { id } }"
    >
      {{ $t('global.more') }}
    </BaseLink>
  </div>
</template>

<script>
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'CellMatchLink',
  components: {
    BaseLink,
  },
  props: {
    id: {
      validator: (id) => _.isNumber(id) || _.isNull(id),
      required: true,
    },
  },
  computed: {
    ...mapGetters('matches', ['getMatch']),
    ...mapGetters('tournaments', ['getTournament']),
    match() {
      return this.getMatch(this.id);
    },
    isTffa() {
      return (
        this.getTournament(this.match?.tournamentId)?.system?.code ===
        'team_ffa'
      );
    },
    isVisible() {
      if (this.isTffa && this.id) {
        return true;
      }
      const teamsHash = [
        this.match.team1?.hash,
        this.match?.team2?.hash,
      ];
      if (teamsHash.filter(Boolean)?.length === 2) {
        return !teamsHash.includes(this.freeSlot.hash);
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-column {
  @include min-laptop() {
    justify-content: flex-end;
  }
}
</style>
